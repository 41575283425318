import { AsyncData, Result } from "@swan-io/boxed";
import { useDeferredQuery, useMutation, useQuery } from "@swan-io/graphql-client";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { animations } from "@swan-io/lake/src/constants/design";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { DatePicker, isDateInRange } from "@swan-io/shared-business/src/components/DatePicker";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { validateIban } from "@swan-io/shared-business/src/utils/validation";
import { combineValidators, useForm } from "@swan-io/use-form";
import dayjs from "dayjs";
import { electronicFormat } from "iban";
import { useState } from "react";
import { P, match } from "ts-pattern";
import {
  AccountCountry,
  AccountSepaMandatesDocument,
  AddSddMandateDocument,
  GetBeneficiaryVerificationDocument,
  GetIbanValidationDocument,
  InitiateSddDocument,
  MerchantRootDocument,
  ScheduleStandingOrderDocument,
} from "../graphql/partner";
import { encodeDateTime } from "../utils/date";
import { locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import {
  validateDateWithinNextYear,
  validateName,
  validateRequired,
  validateTodayOrAfter,
} from "../utils/validations";
import { SepaBeneficiary, TransferWizardBeneficiarySummary } from "./MerchantSepaWizardForm";
import {
  Details,
  TransferSepaWizardDetails,
  TransferSepaWizardDetailsSummary,
} from "./TransferSepaWizardDetails";
import { Schedule, TransferSepaWizardSchedule } from "./TransferSepaWizardSchedule";
import { WizardLayout } from "./WizardLayout";

type Step =
  | {
      name: "Beneficiary";
      beneficiary?: SepaBeneficiary;
    }
  | {
      name: "Create";
    }
  | {
      name: "Details";
      beneficiary: SepaBeneficiary;
      details?: Details;
    }
  | {
      name: "Schedule";
      beneficiary: SepaBeneficiary;
      details: Details;
    };

type Props = {
  onPressClose?: () => void;
  accountCountry: AccountCountry;
  accountId: string;
  accountMembershipId: string;
  canViewAccount: boolean;
};

export const TransferSepaWizard = ({
  onPressClose,
  accountCountry,
  accountId,
  accountMembershipId,
  canViewAccount,
}: Props) => {
  const [submittingSepaMandate, setSubmittingSepaMandate] = useState(false);
  const [submittingInitateSDD, setSubmittingInitateSDD] = useState(false);
  const [scheduleStandingOrder, standingOrderScheduling] = useMutation(
    ScheduleStandingOrderDocument,
  );
  const [AddSddMandate] = useMutation(AddSddMandateDocument);
  const [InitiateSdd] = useMutation(InitiateSddDocument);
  const [merchantProfiles] = useQuery(MerchantRootDocument, { accountId });
  const [sepaMandates] = useQuery(AccountSepaMandatesDocument, { accountId });

  const sepaMandatesMap: any =
    sepaMandates?.value?.value?.account?.holder?.paymentMandates?.edges?.map(sm => {
      return {
        name: sm?.node?.name || "",
        value: sm?.node?.id || "",
      };
    }) || [];

  const recurrence = [
    { name: "Trimestral", value: "quarterly" },
    { name: "Mensual", value: "monthly" },
  ];

  const merchantId =
    merchantProfiles?.value?.value?.account?.merchantProfiles?.edges?.[0]?.node?.id || "";

  const merchantPaymentMethodCore =
    merchantProfiles?.value?.value?.account?.merchantProfiles?.edges?.[0]?.node?.merchantPaymentMethods?.find(
      method => method.__typename === "SepaDirectDebitCoreMerchantPaymentMethod",
    )?.id || "";

  console.log(merchantPaymentMethodCore);

  //const [merchantProfile, { refresh }] = useQuery(MerchantProfileDocument, { merchantProfileId });
  const [step, setStep] = useState<Step>({ name: "Beneficiary" });
  const [ibanVerification, { query: queryIbanVerification, reset: resetIbanVerification }] =
    useDeferredQuery(GetIbanValidationDocument, { debounce: 500 });

  const [
    beneficiaryVerification,
    { query: queryBeneficiaryVerification, reset: resetBeneficiaryVerification },
  ] = useDeferredQuery(GetBeneficiaryVerificationDocument, { debounce: 500 });

  const { Field, listenFields, submitForm, FieldsListener, setFieldValue } = useForm({
    debtorName: {
      initialValue: "",
      validate: validateName,
    },
    iban: {
      initialValue: "",
      sanitize: electronicFormat,
      validate: combineValidators(validateRequired, validateIban),
    },
    mandateName: {
      initialValue: "",
      validate: validateName,
    },
  });

  const {
    Field: MandateField,
    listenFields: listenMandateFields,
    submitForm: submitMandateForm,
    FieldsListener: FieldsMandateListener,
    setFieldValue: setMandateFieldValue,
  } = useForm({
    mandate: {
      initialValue: "",
      validate: validateRequired,
    },
    amount: {
      initialValue: "",
      validate: value => {
        const amount = Number(value);

        if (Number.isNaN(amount) || value === "" || amount <= 0) {
          return t("error.invalidTransferAmount");
        }
      },
    },
    recurrence: {
      initialValue: "",
      validate: validateRequired,
    },
    firstPayDown: {
      initialValue: "",
      validate: combineValidators(validateTodayOrAfter, validateDateWithinNextYear),
    },
  });

  const handleOnPressSubmit = () => {
    submitForm({
      onSuccess: values => {
        setSubmittingSepaMandate(true);
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
        const day = String(today.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        AddSddMandate({
          input: {
            paymentMethodId: merchantPaymentMethodCore,
            sequence: "Recurrent",
            language: "es",
            signatureDate: formattedDate, // Debes generar esta fecha dinámicamente
            debtor: {
              name: values.debtorName.value, // Usa los valores del formulario
              IBAN: values.iban.value, // Usa los valores del formulario
              address: { country: "ESP" },
            },
            name: values.mandateName.value, // Usa los valores del formulario
          },
        })
          .mapOk(data => data.addSepaDirectDebitPaymentMandate)
          .mapOkToResult(filterRejectionsToResult)
          .tapOk(({ paymentMandate }) => {
            // Validar el statusInfo del mandato
            console.log("pm: ", paymentMandate);
            match(paymentMandate.statusInfo)
              .with({ status: "Pending" }, () => {
                showToast({
                  variant: "info",
                  title: "Mandato pendiente",
                  description: "El mandato está en estado pendiente de confirmación.",
                });
              })
              .with({ status: "Enabled" }, () => {
                showToast({
                  variant: "success",
                  title: "Mandato activado",
                  description: "El mandato SEPA ha sido activado correctamente.",
                  autoClose: false,
                });
                setSubmittingSepaMandate(false);
                Router.replace("AccountPaymentsNew", { accountMembershipId });
              })
              .with({ status: "Canceled" }, () => {
                showToast({
                  variant: "error",
                  title: "Mandato cancelado",
                  description: "El mandato ha sido cancelado.",
                });
              });
          })
          .tapError(error => {
            // Manejar los diferentes tipos de errores
            match(error)
              .with({ __typename: "InternalErrorRejection" }, ({ message }) => {
                showToast({ variant: "error", title: "Error interno", description: message });
              })
              .with({ __typename: "PaymentMethodNotCompatibleRejection" }, ({ message }) => {
                showToast({
                  variant: "error",
                  title: "Método de pago incompatible",
                  description: message,
                });
              })
              .with({ __typename: "ValidationRejection" }, ({ message, fields }) => {
                showToast({
                  variant: "error",
                  title: "Error de validación",
                  description: message,
                });
                fields.forEach(field => {
                  console.error(`Error en el campo ${field.path}: ${field.message}`);
                });
              })
              .with(
                { __typename: "PaymentMandateReferenceAlreadyUsedRejection" },
                ({ message }) => {
                  showToast({
                    variant: "error",
                    title: "Referencia de mandato ya utilizada",
                    description: message,
                  });
                },
              )
              .with({ __typename: "SchemeWrongRejection" }, ({ message }) => {
                showToast({
                  variant: "error",
                  title: "Esquema incorrecto",
                  description: message,
                });
              })
              .with({ __typename: "DebtorAccountNotAllowedRejection" }, ({ message }) => {
                showToast({
                  variant: "error",
                  title: "Cuenta de deudor no permitida",
                  description: message,
                });
              })
              .with({ __typename: "DebtorAccountClosedRejection" }, ({ message }) => {
                showToast({
                  variant: "error",
                  title: "Cuenta de deudor cerrada",
                  description: message,
                });
              })
              .with({ __typename: "NotFoundRejection" }, ({ id, message }) => {
                showToast({
                  variant: "error",
                  title: `No se encontró el recurso con ID ${id}`,
                  description: message,
                });
              })
              .with({ __typename: "ForbiddenRejection" }, ({ message }) => {
                showToast({
                  variant: "error",
                  title: "Acción no permitida",
                  description: message,
                });
              })
              .otherwise(() => {
                showToast({
                  variant: "error",
                  title: "Error desconocido",
                  description: "Ocurrió un error inesperado.",
                });
              });
          });
      },
    });
  };

  const onInitiateSDD = () => {
    submitMandateForm({
      onSuccess: values => {
        setSubmittingInitateSDD(true);

        const monthsPending = values.recurrence.value === "monthly" ? 12 : 4;

        let successCount: number = 0; // Contador de solicitudes exitosas
        let failureCount: number = 0; // Contador de solicitudes fallidas
        const errors = []; // Array para almacenar mensajes de error

        for (let i = 0; i < monthsPending; i++) {
          const formattedDate = dayjs(values.firstPayDown.value, "DD/MM/YYYY")
            .add(values.recurrence.value === "monthly" ? i : 3 * i, "month") // Incrementar la fecha en meses
            .set("hour", 0)
            .set("minute", 5)
            .set("second", 0)
            .set("millisecond", 0)
            .utc() // Convertir a UTC
            .format(); // Formato ISO 8601

          InitiateSdd({
            input: {
              amount: {
                value: values.amount.value, // Usa el valor deseado
                currency: "EUR", // Moneda
              },
              sepaDirectDebit: {
                mandateId: values.mandate.value,
                requestedExecutionAt: formattedDate, // Fecha generada dinámicamente
              },
            },
          })
            .mapOk(data => data.initiateMerchantPaymentCollection)
            .mapOkToResult(filterRejectionsToResult)
            .tapOk(({ merchantPaymentCollection }) => {
              // Validar el estado de la colección de pagos
              const { statusInfo } = merchantPaymentCollection;
              match(statusInfo)
                .with({ status: "Initiated" }, () => {
                  successCount++; // Incrementar el contador de éxito
                })
                .with({ status: "ConsentPending" }, () => {
                  // Consentimiento pendiente, aún considerado un éxito
                  successCount++;
                })
                .with({ status: "Rejected" }, ({ reason }) => {
                  failureCount++;
                  errors.push(`Pago rechazado en mes ${i + 1}. Razón: ${statusInfo.reason}`);
                });
            })
            .tapError(error => {
              // Manejar los diferentes tipos de errores
              match(error)
                .with({ __typename: "InternalErrorRejection" }, ({ message }) => {
                  showToast({
                    variant: "error",
                    title: "Error interno",
                    description: message,
                  });
                })
                .with({ __typename: "ValidationRejection" }, ({ message, fields }) => {
                  showToast({
                    variant: "error",
                    title: "Error de validación",
                    description: message,
                  });
                  fields.forEach(field => {
                    console.error(`Error en el campo ${field.path}: ${field.message}`);
                  });
                })
                .with({ __typename: "NotFoundRejection" }, ({ id, message }) => {
                  showToast({
                    variant: "error",
                    title: `No se encontró el recurso con ID ${id}`,
                    description: message,
                  });
                })
                .with({ __typename: "ForbiddenRejection" }, ({ message }) => {
                  showToast({
                    variant: "error",
                    title: "Acción no permitida",
                    description: message,
                  });
                })
                .otherwise(() => {
                  showToast({
                    variant: "error",
                    title: "Error desconocido",
                    description: "Ocurrió un error inesperado.",
                  });
                });
            });
        }

        // Al finalizar todas las llamadas, mostrar los resultados
        if (failureCount === 0) {
          showToast({
            variant: "success",
            title: "Pagos calendarizados",
            description: `Los ${monthsPending} pagos han sido calendarizados correctamente.`,
          });
          setSubmittingInitateSDD(false);
          Router.replace("AccountPaymentsNew", { accountMembershipId });
        } else {
          showToast({
            variant: "error",
            title: "Problemas con algunos pagos",
            description: `${successCount} pagos fueron exitosos, pero ${failureCount} pagos fallaron.`,
          });
          errors.forEach(error => console.error(error)); // Mostrar los errores en consola
          setSubmittingInitateSDD(false);
          Router.replace("AccountPaymentsNew", { accountMembershipId });
        }
      },
    });
  };

  const onSave = ({
    beneficiary,
    details,
    schedule,
  }: {
    beneficiary: SepaBeneficiary;
    details: Details;
    schedule: Schedule;
  }) => {
    const consentRedirectUrl =
      window.location.origin +
      (canViewAccount
        ? Router.AccountPaymentsRecurringTransferList({
            accountMembershipId,
            kind: "standingOrder",
          })
        : Router.AccountPaymentsRoot({ accountMembershipId, kind: "standingOrder" }));

    scheduleStandingOrder({
      input: {
        accountId,
        consentRedirectUrl,
        firstExecutionDate: encodeDateTime(
          schedule.firstExecutionDate,
          `${schedule.firstExecutionTime}:00`,
        ),
        lastExecutionDate:
          isNotNullishOrEmpty(schedule.lastExecutionDate) &&
          isNotNullishOrEmpty(schedule.lastExecutionTime)
            ? encodeDateTime(schedule.lastExecutionDate, `${schedule.lastExecutionTime}:00`)
            : undefined,
        period: schedule.period,
        sepaBeneficiary: {
          name: beneficiary.name,
          save: false,
          iban: beneficiary.iban,
          isMyOwnIban: false, // TODO
        },
        label: details.label,
        reference: details.reference,
        ...match(details)
          .with({ type: "FixedAmount" }, ({ amount }) => ({ amount }))
          .with({ type: "TargetAccountBalance" }, ({ targetAmount }) => ({
            targetAvailableBalance: targetAmount,
          }))
          .exhaustive(),
      },
    })
      .mapOk(data => data.scheduleStandingOrder)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(({ standingOrder }) => {
        match(standingOrder.statusInfo)
          .with({ __typename: "StandingOrderConsentPendingStatusInfo" }, ({ consent }) => {
            window.location.assign(consent.consentUrl);
          })
          .with({ __typename: "StandingOrderCanceledStatusInfo" }, () => {
            showToast({
              variant: "error",
              title: t("recurringTransfer.consent.error.rejected.title"),
              description: t("recurringTransfer.consent.error.rejected.description"),
            });
          })
          .with({ __typename: "StandingOrderEnabledStatusInfo" }, () => {
            showToast({
              variant: "success",
              title: t("recurringTransfer.consent.success.title"),
              description: t("recurringTransfer.consent.success.description"),
              autoClose: false,
            });
            Router.replace("AccountPaymentsRoot", { accountMembershipId });
          })
          .exhaustive();
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return (
    <WizardLayout title="Selecciona o crea un Mandato SEPA" onPressClose={onPressClose}>
      {({ large }) =>
        match(step)
          .with({ name: "Create" }, () => (
            <>
              <Tile style={animations.fadeAndSlideInFromBottom.enter}>
                <LakeHeading level={2} variant="h3">
                  Crear nuevo mandato SEPA
                </LakeHeading>

                <Space height={16} />

                <LakeLabel
                  label="Nombre del vecino"
                  render={id => (
                    <Field name="debtorName">
                      {({ value, onChange, onBlur, error, valid, ref }) => {
                        return (
                          <LakeTextInput
                            id={id}
                            ref={ref}
                            value={value}
                            error={error}
                            valid={valid}
                            onChangeText={onChange}
                            onBlur={onBlur}
                          />
                        );
                      }}
                    </Field>
                  )}
                />

                <Space height={8} />

                <LakeLabel
                  label="IBAN del vecino"
                  render={id => (
                    <Field name="iban">
                      {({ value, onChange, onBlur, error, valid, ref }) => {
                        const shouldWarn = match(
                          beneficiaryVerification.mapOk(query => query.beneficiaryVerification),
                        )
                          .with(
                            AsyncData.P.Done(
                              Result.P.Ok(
                                P.union(
                                  { __typename: "InvalidBeneficiaryVerification" },
                                  { __typename: "BeneficiaryMismatch", accountStatus: "Inactive" },
                                ),
                              ),
                            ),
                            () => true,
                          )
                          .otherwise(() => false);
                        return (
                          <LakeTextInput
                            id={id}
                            ref={ref}
                            value={value}
                            error={error}
                            valid={valid}
                            onChangeText={onChange}
                            onBlur={onBlur}
                            warning={shouldWarn}
                          />
                        );
                      }}
                    </Field>
                  )}
                />

                <Space height={8} />

                <LakeLabel
                  label="Nombre del mandato SEPA"
                  render={id => (
                    <Field name="mandateName">
                      {({ value, onChange, onBlur, error, valid, ref }) => {
                        return (
                          <LakeTextInput
                            id={id}
                            ref={ref}
                            value={value}
                            error={error}
                            valid={valid}
                            onChangeText={onChange}
                            onBlur={onBlur}
                          />
                        );
                      }}
                    </Field>
                  )}
                />
              </Tile>

              <Space height={16} />

              <ResponsiveContainer breakpoint={800}>
                {({ small }) => (
                  <LakeButtonGroup>
                    <LakeButton
                      mode="secondary"
                      color="gray"
                      onPress={() => setStep({ name: "Beneficiary" })}
                      grow={small}
                      disabled={submittingSepaMandate}
                    >
                      {t("common.previous")}
                    </LakeButton>

                    <LakeButton
                      color="current"
                      onPress={handleOnPressSubmit}
                      grow={small}
                      loading={submittingSepaMandate}
                    >
                      {t("accountDetails.settings.save")}
                    </LakeButton>
                  </LakeButtonGroup>
                )}
              </ResponsiveContainer>
            </>
          ))
          .with({ name: "Beneficiary" }, ({ beneficiary }) => (
            <>
              <Space height={32} />

              <LakeButton color="current" onPress={() => setStep({ name: "Create" })}>
                Crear nuevo mandato SEPA
              </LakeButton>

              <Space height={32} />

              {sepaMandatesMap.length > 0 && (
                <Tile>
                  <LakeLabel
                    label="Selecciona un mandato Sepa sobre el que quieres cargar el cobro mensual"
                    render={id => (
                      <MandateField name="mandate">
                        {({ value, onChange, error, valid, ref }) => {
                          return (
                            <LakeSelect
                              id={id}
                              ref={ref}
                              value={value}
                              placeholder="Selecciona un mandato sepa que cargar..."
                              items={sepaMandatesMap}
                              onValueChange={onChange}
                            />
                          );
                        }}
                      </MandateField>
                    )}
                  />

                  <LakeLabel
                    label="Introduce un importe"
                    render={id => (
                      <MandateField name="amount">
                        {({ value, onChange, error, valid, ref }) => {
                          return (
                            <LakeTextInput
                              unit="EUR"
                              id={id}
                              ref={ref}
                              value={value}
                              error={error}
                              valid={valid}
                              onChangeText={onChange}
                              // onBlur={onBlur}
                              // warning={shouldWarn}
                            />
                          );
                        }}
                      </MandateField>
                    )}
                  />

                  <LakeLabel
                    label="Recurrencia"
                    render={id => (
                      <MandateField name="recurrence">
                        {({ value, onChange, error, valid, ref }) => {
                          return (
                            <LakeSelect
                              id={id}
                              ref={ref}
                              value={value}
                              placeholder="Selecciona la recurrencia"
                              items={recurrence}
                              onValueChange={onChange}
                            />
                            // <LakeTextInput
                            //   id={id}
                            //   ref={ref}
                            //   value={value}
                            //   error={error}
                            //   valid={valid}
                            //   disabled={true}
                            //   onChangeText={onChange}
                            //   // onBlur={onBlur}
                            //   // warning={shouldWarn}
                            // />
                          );
                        }}
                      </MandateField>
                    )}
                  />

                  <MandateField name="firstPayDown">
                    {({ value, onChange, error, valid, ref }) => {
                      return (
                        <DatePicker
                          label="Fecha del primer pago"
                          value={value}
                          error={error}
                          format={locale.dateFormat}
                          firstWeekDay={locale.firstWeekday}
                          onChange={onChange}
                          isSelectable={isDateInRange(
                            dayjs.utc().toDate(),
                            dayjs.utc().add(1, "year").toDate(),
                          )}
                        />
                        // <LakeTextInput
                        //   id={id}
                        //   ref={ref}
                        //   value={value}
                        //   error={error}
                        //   valid={valid}
                        //   onChangeText={onChange}
                        //   // onBlur={onBlur}
                        //   // warning={shouldWarn}
                        // />
                      );
                    }}
                  </MandateField>

                  <LakeText>Los mandatos sepa tendrán una duración de 1 año.</LakeText>
                  <Space height={16} />

                  <LakeButton color="current" onPress={onInitiateSDD}>
                    Confirmar
                  </LakeButton>
                </Tile>
              )}

              {/* <MerchantSepaWizardForm
                mode="continue"
                accountCountry={accountCountry}
                accountId={accountId}
                saveCheckboxVisible={false}
                onPressSubmit={beneficiary => setStep({ name: "Details", beneficiary })}
                initialBeneficiary={match(beneficiary)
                  .with({ kind: "new" }, identity)
                  .with({ kind: "saved" }, P.nullish, () => undefined)
                  .exhaustive()}
              /> */}
            </>
          ))
          .with({ name: "Details" }, ({ beneficiary, details }) => (
            <>
              <TransferWizardBeneficiarySummary
                isMobile={!large}
                beneficiary={beneficiary}
                onPressEdit={() => setStep({ name: "Beneficiary", beneficiary })}
              />

              <Space height={32} />

              <TransferSepaWizardDetails
                initialDetails={details}
                onPressPrevious={() => setStep({ name: "Beneficiary", beneficiary })}
                onSave={details => setStep({ name: "Schedule", beneficiary, details })}
              />

              <Space height={32} />
            </>
          ))
          .with({ name: "Schedule" }, ({ beneficiary, details }) => (
            <>
              <TransferWizardBeneficiarySummary
                isMobile={!large}
                beneficiary={beneficiary}
                onPressEdit={() => setStep({ name: "Beneficiary", beneficiary })}
              />

              <Space height={32} />

              <TransferSepaWizardDetailsSummary
                isMobile={!large}
                details={details}
                onPressEdit={() => setStep({ name: "Details", beneficiary, details })}
              />

              <Space height={32} />

              <LakeHeading level={2} variant="h3">
                {t("transfer.new.schedule.title")}
              </LakeHeading>

              <Space height={32} />

              <TransferSepaWizardSchedule
                loading={standingOrderScheduling.isLoading()}
                onPressPrevious={() => setStep({ name: "Details", beneficiary, details })}
                onSave={schedule => onSave({ beneficiary, details, schedule })}
              />
            </>
          ))
          .otherwise(() => null)
      }
    </WizardLayout>
  );
};
